@tailwind components;
@tailwind utilities;
body {
    margin: unset;
    font-family: 'Noto Sans JP', sans-serif !important;
    font-weight: 500;
    p {
        margin: 0px;
    }
    span {
        padding: unset;
    }
    .ant-form-item {
        label {
            color: #666 !important;
            font-weight: 500;
        }
    }
}
.ant-modal,
.ant-form-item-required,
span,
div {
    font-family: 'Noto Sans JP', sans-serif !important;
    font-weight: 500;
}

.ant-select-selector,
.ant-select-single {
    height: 40px !important;
    border-radius: 2px !important;
    line-height: 40px;
}
