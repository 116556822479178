.layout-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 100vh;
    // min-width: 828px;
}
.register-container {
    max-width: 828px;
    width: 100%;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--White-White-100, #fff);
    box-shadow: 2px 2px 6px 0px rgba(169, 182, 187, 0.5);
    display: flex;
    .text-color {
        color: #666;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .check-agree {
        .ant-checkbox {
            width: 21px;
            height: 21px;
            .ant-checkbox-inner {
                width: 100%;
                height: 100%;
                border: 2px solid;
            }
        }
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #4a4e51 !important;
        }
    }
    .ant-form-item {
        margin-bottom: 16px;
        .ant-form-item-required {
            &::before {
                content: unset !important;
            }
        }
    }
    .ant-select-single {
        .ant-select-selector {
            border-radius: 2px;
        }
    }
    .ant-form-item-control-input-content {
        .ant-input-affix-wrapper {
            height: 48px;
            padding: 0 11px;
            box-sizing: border-box;
            border: 2px solid #e7e7e7;
            border-radius: 5px;
            input {
                height: 100%;
            }
        }
        .input-text,
        .ant-input-password {
            height: 48px;
            border-radius: 5px;
            line-height: 40px;
        }
        .ant-btn-primary:disabled {
            background: #e252524d !important;
        }
    }
    .icon-password {
        position: absolute;
        right: 10px;
        top: 11px;
        height: 24px;
    }
    .ant-form-item-explain {
        margin: 5px 0px;
    }
    .ant-form-item-explain-error {
        font-size: 12px;
        text-align: center;
        text-wrap: nowrap;
        white-space: nowrap;
    }
    button {
        cursor: pointer;
    }
}
.modal-delete {
    .ant-modal-content {
        min-height: 194px;
        border-radius: unset;
        padding: 35px 30px;
        border-radius: 6px;
    }
}
@media only screen and (max-width: 840px) {
    .register-container {
        min-height: 100vh;
        min-width: 100vw;
    }
}
@media only screen and (max-width: 500px) {
    .popup-message {
        .ant-notification-notice-message {
            font-size: 12px !important;
        }
    }
}

@media only screen and (max-width: 350px) {
    .login-container {
        .ant-form-item-explain-error {
            text-wrap: unset;
        }
    }
}
